@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.verticalScroll::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}
.verticalScroll::-webkit-scrollbar-track {
  background-color: rgba(160, 174, 192, 0.4);
  cursor: pointer;
}
.verticalScroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}
.verticalScroll {
  height: calc(100vh - 12.5rem);
}

.custom {
  margin-left: -90px;
}

.hire {
  padding: 20px 20px;
  display: inline-block;
  color: #008000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 3em;
  overflow: hidden;
}
.hire:hover {
  color: #111;
  background: #4caf50;
  box-shadow: 0 0 50px #4caf50;
}

/* .confirmButtonHire{
    border-width: 2px;
    padding: 4px;
    border-radius: 10px;
    margin-left: 30px;
    border-color: black;
    cursor: pointer;
}

.confirmButtonHire:hover{
    background: #4CAF50;
} */
.cancelHireModel {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  /* color: #fff; */
  /* text-transform: uppercase; */
  font-size: 1rem;
  background-color: #ff0800;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 0px 12px;
}

.cancelHireModel:hover {
  color: #fff;
}

.confirmButtonHire {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  /* color: #fff; */
  /* text-transform: uppercase; */
  font-size: 15.5px;
  background-color: #0cf;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.confirmButtonHire:hover {
  color: #fff;
}
.notifbutton {
  padding: 7px;
  line-height: inherit;
  color: black;
  background: #0cf;
  border-radius: 57px;
  margin-top: -62px;
}
